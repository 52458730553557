<template>
  <div class="table-employers">
    <v-card>
      <v-app class="table-v-app">
        <tippy
          toSelector=".table-v-app .v-data-table-header"
          class="tooltip"
          v-if="showHelp"
          :placement="'top'"
          followCursor="true"
        >
          <template>
            <div class="tooltip">
              <span class="custom"
                >Click on title to sort ascending or descending</span
              >
            </div>
          </template>
        </tippy>
        <tippy
          toSelector="#selectedEmployeeTable"
          class="tooltip"
          v-if="showHelp"
          followCursor="true"
          placement="top"
        >
          <template>
            <div class="tooltip">
              <span class="custom">
                Enter GROUP name and drag-and-drop users here to construct your
                groups
              </span>
            </div>
          </template>
        </tippy>
        <v-data-table
          class="table-manager"
          item-key="id"
          :headers="headers"
          :items="tableList"
          :items-per-page="-1"
          hide-default-footer
          :loading="disableButtonAndLoadTable"
        >
          To remove, drag the name back to the employee list
          <template v-slot:body="props">
            <draggable
              tag="tbody"
              id="selectedEmployeeTable"
              :list="tableList"
              :disabled="disableDropDown()"
              :group="{ name: 'selectedEmployers', put: 'employeeList'}"
              @change="updateBlock"
              draggable=".draggableListUser"
            >
              <tr
                v-for="(user, index) in props.items"
                :key="index"
                class="draggableListUser"
              >
                <td>{{ user.name }}</td>
                <td>{{ user.surName }}</td>
                <td class="emails">{{ user.email }}</td>
                <td>{{ user.phone }}</td>
                <td :class="checkCorrectColor(user.colorInvitation)">
                  {{ user.invitationSend }}
                </td>
                <td :class="checkCorrectColor(user.colorReminder1)">
                  {{ checkComplete(user.completeU1, user.countCompleteU2,
                  user.reminderSent1, user.invitationSend, user.reminderSent2,
                  false, user.id, user.invitationSend, user.countAllGroup,
                  user.countCompleteEmployee)}}
                </td>
                <td :class="checkCorrectColor(user.colorReminder2)">
                  {{ checkComplete(user.completeU1, user.countCompleteU2,
                  user.reminderSent2, user.reminderSent1, user.reminderSent2,
                  true, user.id, user.invitationSend, user.countAllGroup,
                  user.countCompleteEmployee)}}
                </td>
              </tr>
              <tr v-for="(user, index) in tempUser" :key="'A'+ index">
                <td class="text-center">{{ user.temp1 }}</td>
                <td class="text-center">{{ user.temp2 }}</td>
                <td class="text-center">{{ user.temp3 }}</td>
                <td class="text-center">{{ user.temp4 }}</td>
                <td class="text-center">{{ user.temp5 }}</td>
                <td class="text-center">{{ user.temp6 }}</td>
                <td class="text-center">{{ user.temp7 }}</td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
        <div class="footer-selected-employee" v-if="showButton">
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentButtonSend === true"
            @click.prevent="buttonPause"
            dark
            id="button-reset-pause"
            class="buttons-selected-employers"
          >
            PAUSE/STOP</v-btn
          >
          <!--suppress XmlDuplicatedId -->
          <v-btn
            v-else-if="checkRetryAutoRemind"
            @click.prevent="openModalAutoRemind"
            :disabled="disableButtonAndLoadTable"
            id="send_remind"
            class="buttons-selected-employers"
          >
            MORE <br />REMINDERS</v-btn
          >
          <!--suppress XmlDuplicatedId -->
          <v-btn
            v-else-if="checkSendAutoRemind"
            :disabled="disableButtonSend || disableButtonAndLoadTable || disableSend"
            @click.prevent="openModalAutoRemind"
            id="send_remind"
            class="buttons-selected-employers"
          >
            Send</v-btn
          >
          <!--suppress XmlDuplicatedId -->
          <v-btn
            v-else-if="currentButtonSend === null"
            :disabled="disableButtonSend || disableButtonAndLoadTable || disableSend"
            @click.prevent="openModalAutoRemind"
            id="send_remind"
            class="buttons-selected-employers"
          >
            Send</v-btn
          >
          <v-btn
            v-else-if="currentButtonSend === false"
            @click.prevent="resumeAutoRemind"
            class="buttons-selected-employers"
            dark
          >
            Resume</v-btn
          >
          <v-btn
            @click.prevent="openModalClearAll"
            :disabled="disableClearAll"
            class="buttons-selected-employers"
          >
            Clear All</v-btn
          >
          <v-dialog v-model="showCancelModal" width="210">
            <v-card>
              <v-card-title> </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="buttons-selected-employers"
                  @click="buttonPauseCansel"
                  >PAUSE</v-btn
                >
                <v-btn
                  color="buttons-selected-employers"
                  id="button-reset"
                  @click="buttonRESET"
                  >RESET</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <BasePopup
            title="Are you sure?"
            max-width="500"
            cancelBtn="Cancel"
            confirmBtn="Yes"
            @confirm="confirmClearAll"
            ref="clearAllModal"
          />
          <BasePopup
            title="ERROR"
            type="Error"
            width="500"
            :message="`This group is has the same employees as
              ${errorTheSameEmployee.duplicateGroupName}. Please amend
              the employees or refer to the results for
              ${errorTheSameEmployee.originalGroupName}`"
            cancelBtn="Okay"
            ref="errorTheSameEmployeeModal"
          />
          <BasePopup
            max-width="500"
            cancelBtn="Cancel"
            confirmBtn="Yes"
            @confirm="confirmAutoRemind"
            ref="showModalAutoRemind"
          >
            <template slot="inner-title">
              Remind every
              <v-text-field
                class="remindEvery"
                id="remind-every-select-time"
                @keypress="isNumber($event)"
                v-model.number="numberValue"
                hide-details
                single-line
              />
              day(s)
            </template>
          </BasePopup>
        </div>
      </v-app>
    </v-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'TablesEmployers',
  components: {
    draggable,
  },
  props: {
    showHelp: {
      type: Boolean,
      default: true,
    },
    dataEmployee: {
      type: Array,
      default: () => [],
    },
    department: {
      type: Object,
      default: () => {},
    },
    getDepartments: {
      type: Function,
    },
  },
  data() {
    return {
      openModalLeastLeastOneType: false,
      checkLeastOneType: [],
      showButton: false,
      showCancelModal: false,
      setUpRemind: false,
      currentButtonSend: null,
      disableButtonSend: true,
      disableClearAll: false,
      allPeopleGetAllReminders: false,
      disableSend: false,
      numberValue: 1,
      checkSecondStage: false,
      employeeCompleted: [],
      employeeIncompletedAndCompleted: new Set(),
      tableList: [],
      tempUser: [],
      disableButtonAndLoadTable: false,
      errorTheSameEmployee: {},
      headers: [
        {
          text: 'NAME', value: 'name', align: 'center', sortable: false,
        },
        {
          text: 'SECOND NAME', value: 'surName', align: 'center', sortable: false,
        },
        {
          text: 'EMAIL', value: 'email', align: 'center', sortable: false,
        },
        {
          text: 'PHONE', value: 'phone', align: 'center', sortable: false,
        },
        {
          text: 'INVITATION SENT',
          value: 'invitationSend',
          align: 'center',
          sortable: false,
        },
        {
          text: 'REMINDER # SENT',
          value: 'reminderSent1',
          align: 'center',
          sortable: false,
        },
        {
          text: 'REMINDER # SENT',
          value: 'reminderSent2',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    checkRetryAutoRemind() {
      return (this.currentButtonSend === null
        && (this.department.countRetry === 2 && this.checkSecondStage))
        || (this.department.countRetry === 1 && this.allPeopleGetAllReminders
          && this.tableList.length >= 5);
    },
    checkSendAutoRemind() {
      return this.currentButtonSend === null
        && (this.department.countRetry === 2 && this.allPeopleGetAllReminders);
    },
  },
  mounted() {
    this.showButton = this.tableList.length > 0;
    if (this.tableList.length === 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        this.tempUser.push({
          temp1: '', temp2: '', temp3: '', temp4: '', temp5: '', temp6: '', temp7: '',
        });
      }
    }
  },
  updated() {
    if (this.tableList.length !== 0) {
      this.allPeopleGetAllReminders = this.tableList.every(x => x.reminderSent2
      || this.employeeCompleted.find(y => y === x.id));
      this.checkSecondStage = this.tableList.every(x => (x.reminderSent1 && !x.reminderSent2)
        || this.employeeCompleted.find(y => y === x.id));
    }
  },
  watch: {
    disableClearAll() {
      this.$emit('disable-clear-all', this.disableClearAll);
    },
    tableList() {
      this.showButton = this.tableList.length > 0;
      this.disableButtonSend = !(this.tableList.length >= 5);
      this.employeeCompleted = [];
      this.employeeIncompletedAndCompleted = new Set();
      this.disableSend = this.tableList.every(x => x.reminderSent2);
      if (this.tableList.length <= 9) {
        this.tempUser = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 9 - this.tableList.length; i++) {
          this.tempUser.push({
            temp1: '', temp2: '', temp3: '', temp4: '', temp5: '', temp6: '', temp7: '',
          });
        }
      }
      if (this.tableList.length > 10) {
        this.tempUser = [];
      }
    },
    dataEmployee() {
      this.tableList = this.dataEmployee;
    },
    department() {
      if (this.department) {
        this.getDinamicHeaders();
        if (this.department.autoRemindSwitchOff === true) {
          this.disableClearAll = true;
        }
        if (this.department.autoRemindSwitchOff === false) {
          this.disableClearAll = true;
        }

        if (this.department.daysAutoRemindEvery) {
          this.numberValue = this.department.daysAutoRemindEvery;
          this.currentButtonSend = this.department.autoRemindSwitchOff;
          this.setUpRemind = true;
        } else {
          this.numberValue = 1;
          this.setUpRemind = false;
          this.currentButtonSend = null;
          this.disableClearAll = false;
        }
      }
    },
  },
  methods: {
    disableDropDown() {
      if (this.department && this.department.countRetry === 2) {
        return this.disableClearAll || (this.department.countRetry === 2);
      }
      return this.disableClearAll;
    },
    getDinamicHeaders() {
      if (this.department) {
        this.headers = [
          {
            text: 'NAME', value: 'name', align: 'center', sortable: false,
          },
          {
            text: 'SECOND NAME', value: 'surName', align: 'center', sortable: false,
          },
          {
            text: 'EMAIL', value: 'email', align: 'center', sortable: false,
          },
          {
            text: 'PHONE', value: 'phone', align: 'center', sortable: false,
          },
          {
            text: 'INVITATION SENT',
            value: 'invitationSend',
            align: 'center',
            sortable: false,
          },
          {
            text: `REMINDER #${this.department.countRetry + (this.department.countRetry - 1)} SENT`,
            value: 'reminderSent1',
            align: 'center',
            sortable: false,
          },
          {
            text: `REMINDER #${this.department.countRetry + this.department.countRetry} SENT`,
            value: 'reminderSent2',
            align: 'center',
            sortable: false,
          },
        ];
      }
    },
    checkCorrectColor(colorField) {
      switch (colorField) {
      case 'PURPLE':
        return 'complete-people-u1 complete-people-u2-equal-total';
      case 'GREEN':
        return 'complete-people-u1 complete-people-u2-less-total';
      case 'RED':
        return 'complete-people-u1 complete-people-u2-less4';
      case 'BOLD':
        return 'complete-people-u1';
      default:
        return '';
      }
    },
    addedAndFilterCompletedUsers(id) {
      this.employeeCompleted.push(id);
      this.employeeIncompletedAndCompleted.add(id);
    },
    addedAndFilterIncompletedUsers(id) {
      this.employeeIncompletedAndCompleted.add(id);
    },
    checkComplete(completeU1, countCompleteU2, remind, checkBeforeData, checkIncomplete,
      lastReminder, id, checkInvitation, countAllGroup, countCompleteEmployee) {
      if (checkInvitation) {
        if (completeU1 && countCompleteU2 === countAllGroup - 1) {
          if (countAllGroup > this.employeeIncompletedAndCompleted.size + countCompleteEmployee) {
            this.addedAndFilterCompletedUsers(id);
          }
          if (checkIncomplete === null) {
            return 'Complete';
          }
          if (remind && checkIncomplete && lastReminder) {
            return 'Complete';
          }

          if (!checkIncomplete) {
            return 'Complete';
          }

          return remind;
        }
      }
      if (checkBeforeData) {
        if (!completeU1 && lastReminder && checkIncomplete) {
          if (countAllGroup > this.employeeIncompletedAndCompleted.size + countCompleteEmployee) {
            this.addedAndFilterIncompletedUsers(id);
          }
          return 'Incomplete';
        }
        if ((completeU1 && countCompleteU2 === 0) || (completeU1 && countCompleteU2 <= 4)
        || (completeU1 && countCompleteU2 > 3 && countCompleteU2 <= countAllGroup)) {
          if (checkIncomplete && lastReminder) {
            if (countAllGroup > this.employeeIncompletedAndCompleted.size + countCompleteEmployee) {
              this.addedAndFilterIncompletedUsers(id);
            }
            return 'Incomplete';
          }
        }
      }
      if (countAllGroup > this.employeeIncompletedAndCompleted.size + countCompleteEmployee) {
        this.addedAndFilterIncompletedUsers(id);
      }
      return remind;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    isNumber($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (((keyCode > 31 && (keyCode < 48 || keyCode > 57)))) {
        $event.preventDefault();
      }
      if (this.numberValue < 0 || this.numberValue > 10) {
        $event.preventDefault();
      }
    },
    async resumeAutoRemind() {
      if (!this.department) return;
      await this.$api.manage.resumeAutoReminders(this.department.id);
      this.currentButtonSend = true;
      this.disableClearAll = true;
      await this.getDepartments();
      this.setUpRemind = false;
      this.showCancelModal = false;
    },
    openModalClearAll() {
      this.$refs.clearAllModal.open();
    },
    async confirmClearAll() {
      if (this.department) {
        await this.$api.manage.clearAllAdminPanel(this.department.id);
        await this.getDepartments();
      }
      this.$refs.clearAllModal.close();
    },
    openModalAutoRemind() {
      this.$refs.showModalAutoRemind.open();
    },
    buttonPause() {
      this.showCancelModal = true;
    },
    async buttonRESET() {
      if (this.department) return;
      await this.$api.manage.resetAutoReminders(this.department.id);
      this.currentButtonSend = null;
      this.disableClearAll = false;
      this.numberValue = 1;
      await this.getDepartments();
      this.setUpRemind = false;
      this.showCancelModal = false;
    },
    async buttonPauseCansel() {
      if (this.department) return;
      await this.$api.manage.pauseAutoReminders(this.department.id);
      this.currentButtonSend = false;
      this.setUpRemind = false;
      this.showCancelModal = false;
      this.disableClearAll = true;
    },
    async confirmAutoRemind() {
      if (!this.department) return;
      this.disableButtonAndLoadTable = true;
      try {
        if (this.checkRetryAutoRemind) {
          const competedFilter = this.employeeCompleted.filter(this.onlyUnique);
          await this.$api.manage.retryAutoReminders(
            this.department.id, this.numberValue, competedFilter,
          );
          this.currentButtonSend = true;
          this.disableClearAll = true;
          await this.getDepartments();
        } else if (this.checkSendAutoRemind || this.currentButtonSend === null) {
          const data = await this.$api.manage.saveAutoReminders(
            this.department.id, this.numberValue,
          );
          this.errorTheSameEmployee = {
            duplicateGroupName: data.duplicateGroupName,
            originalGroupName: data.originalGroupName,
          };
          if (data.findSimilar) this.$refs.errorTheSameEmployeeModal.open();
          this.currentButtonSend = true;
          this.disableClearAll = true;
          await this.getDepartments();
        }
      } catch (error) {
        console.log(error);
      }
      this.disableButtonAndLoadTable = false;
      this.setUpRemind = true;
      this.showModalAutoRemind = false;
    },
    async updateBlock(evt) {
      if (evt.moved || evt.added || evt.removed) return;
      if (evt.added.element || evt.added[0].element) {
        await this.$api.manage.saveEmployeeToManager(
          this.department.id,
          evt.added.element ? [evt.added.element.id] : evt.added.map(x => x.element.id),
        );
      }
      if (evt.removed.element) {
        await this.$api.manage.removeOneEmployee(this.department.id, evt.removed.element.id);
        this.$emit('enlarge-text', 1);
      }
      await this.getDepartments();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.table-employers {
  .buttons-selected-employers {
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 15px;
    background-color: $btnColor1 !important;
    color: white !important;
  }
  .remindEvery {
    max-width: 60px;
    padding-bottom: 9px;
    padding-left: 12px;
  }
  .invitation-sent-selected {
    display: flex;
  }
  .button-confirm {
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .table-v-app .v-label {
    top: 3px;
    margin-right: 10px;
  }
  .select-type-text {
    position: relative;
    right: 10px;
    font-weight: bold;
  }
  .select-type-td {
    cursor: pointer;
  }
  .footer-selected-employee {
    margin-top: 15px;
  }
  .complete-people-u1 {
    font-weight: bold;
  }
  .complete-people-u2-less4 {
    color: #ec6b66;
  }
  .complete-people-u2-less-total {
    color: #17cc00;
  }
  .complete-people-u2-equal-total {
    color: #6a27ff;
  }
  .no-item-text {
    padding-left: 40px;
    font-size: 18px;
    color: #9a9a9a;
    font-family: sans-serif Montserrat;
    font-weight: bold;
    font-style: italic;
  }
  .table-manager {
    min-height: 500px;
  }
}
</style>
