<template>
  <div class="admin-panel">
    <draggable
      class="admin-panel-content"
      :list="trashList"
      handle=".handle"
      v-bind="trashOptions"
    >
      <div class="col-8">
        <div>
          <div class="manager-name">
            <v-toolbar>
              <span class="text-bold">
                MANAGER: {{`${getProfile.name}
                ${getProfile.lastName}`.toUpperCase()}}</span
              >
            </v-toolbar>
          </div>
          <div class="switch-help">
            <v-app>
              <v-toolbar>
                <span class="text-bold">Help text:</span>
                <v-switch
                  class="show-switch-text"
                  v-model="showHelp"
                  @change="changeSwitch"
                  inset
                ></v-switch>
              </v-toolbar>
            </v-app>
          </div>
          <div
            class="manager-remind-time"
            v-if="department != null && department.timeNextRemind"
          >
            <v-toolbar>
              <span class="text-bold">NEXT REMINDER: {{
                formatDate(department.timeNextRemind)}}</span>
            </v-toolbar>
          </div>
          <div class="no-next-reminder" v-else></div>
          <SelectedEmployers
            :dataEmployee="tableData"
            :department.sync="department"
            :getDepartments="getDepartments"
            :showHelp="showHelp"
            v-on:enlarge-text="updateEmployeeList += $event"
            v-on:disable-clear-all="disableMoveItem = $event"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="list-employers">
          <GroupEmployee
            v-model="department"
            :showHelp="showHelp"
            :getDepartments="getDepartments"
            :checkUpdated="checkUpdated"
            @resetTable="resetTable"
          />
          <EmployeeList
            :department="department"
            :update="updateEmployeeList"
            :removeGroup="removeGroup"
            :showHelp="showHelp"
            :getDepartments="getDepartments"
            :disableMoveItem="disableMoveItem"
          />
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import SelectedEmployers from '@modules/AdminPanel/SelectedEmployers.vue';
import EmployeeList from '@modules/AdminPanel/EmployeeList.vue';
import GroupEmployee from '@modules/AdminPanel/GroupEmployee.vue';

export default {
  name: 'adminPanel',
  display: 'Two Lists',
  order: 1,
  components: {
    SelectedEmployers,
    EmployeeList,
    draggable,
    GroupEmployee,
  },
  data() {
    return {
      tableData: [],
      removeGroup: 1,
      department: null,
      updateEmployeeList: 1,
      disableMoveItem: false,
      showHelp: false,
      timer: 0,
      trashOptions: {
        group: {
          name: 'trash',
          ghostClass: 'ghost',
          put: ['selectedEmployers'],
          pull: false,
        },
      },
      trashList: [],
    };
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  async mounted() {
    await this.getDepartments();
    this.checkUpdated();
    await this.changeSwitch();
  },
  methods: {
    async getDepartments() {
      const res = await this.$api.manage.getDepartments();
      this.department = res.find(x => x.selectedDepartment === true);
      this.items = res;
      await this.loadDataAdminPanel();
    },
    async selectOtherDepartments() {
      if (!this.department) return;
      await this.$api.manage.selectedDepartmentByDefault(this.department.id);
      const res = await this.$api.manage.getDepartments();
      this.department = res.find(x => x.selectedDepartment === true);
      this.items = res;
      await this.loadDataAdminPanel();
    },
    async loadDataAdminPanel() {
      if (!this.department) return;
      const res = await this.$api.manage.getDataAdminPanel(this.department.id);
      this.tableData = res.map(x => ({
        ...x.employeeDto,
        email: x.employeeDto.corporateEmail,
      }));
    },
    async changeSwitch(changeType) {
      const res = await this.$api.manage.offTextHelp(changeType);
      this.showHelp = res === '' ? true : res;
    },
    formatDate(value) {
      return `${this.$moment(value).locale('en-GB').format('dddd, MMMM D [at] hh:mm a')}`;
    },
    checkUpdated() {
      const that = this;
      clearTimeout(this.timer);
      that.timer = setTimeout(async function tick() {
        if (!that.department) return;
        const res = await that.$api.manage.checkUpdatedData(that.department.id);
        if (res) await that.getDepartments();
        that.timer = setTimeout(tick, 30000);
      }, 30000);
    },
    resetTable() {
      this.removeGroup = this.removeGroup + 1;
      this.tableData = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.admin-panel {
  .admin-panel-content {
    display: flex;
  }
  .manager-name {
    max-width: 430px;
    display: inline-block;
    margin-right: 40%;
  }
  .manager-remind-time {
    display: inline-block;
    margin-right: 30%;
  }
  .no-next-reminder {
    margin-top: 70px;
  }
  .switch-help {
    width: 200px;
    display: inline-block;
  }
  .show-switch-text {
    padding-top: 18px !important;
    padding-left: 20px !important;
  }
  .text-bold {
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
