<template>
  <div class="department">
    <tippy toSelector=".input-department" class="tooltip" v-if="showHelp">
      <template>
        <div class="tooltip">
          <span class="custom">Create, Select and Delete Groups</span>
        </div>
      </template>
    </tippy>
    <v-app class="input-department">
      <v-toolbar>
        <span class="text-bold">GROUP:</span>
        <v-autocomplete
          ref="autocomplete"
          v-model="department"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true,
          }"
          :search-input.sync="departmentName"
          :items="items"
          item-text="name"
          item-value="id"
          flat
          hide-no-data
          hide-details
          return-object
          label="Enter Group name"
          solo
          @change="$emit('selectOtherDepartments')"
          :disabled="disableAutoComplete"
          data-app="true"
          @keyup.enter.stop="newDepartmentEv"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
            <v-btn
              icon
              color="blue darken-1"
              @click="openModalUpdateDepartment"
              text
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red darken-1"
              @click.stop="openModalDeleteDepartment(item.id, item.name)"
              text
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
        <v-btn icon @click.prevent.stop="newDepartmentEv">
          <v-icon :color="changeButtonColor">mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <BasePopup
        :title="`Are you sure you want to delete ${removeDepartmentName} ?`"
        max-width="500"
        cancelBtn="Cancel"
        confirmBtn="Yes"
        @confirm="confirmDeleteDepartment"
        ref="modalDeleteDepartment"
      />
      <BasePopup
        max-width="500"
        cancelBtn="Cancel"
        confirmBtn="Yes"
        @confirm="departmentSave(department.id)"
        ref="modalUpdateDepartment"
      >
        <template slot="inner-title">
          Update Group:
          <v-text-field
            class="updated-department"
            v-model="departmentName"
            hide-details
            single-line
          />
        </template>
      </BasePopup>
    </v-app>
    <div class="margin-group"></div>
  </div>
</template>

<script>
export default {
  props: {
    showHelp: Boolean,
    getDepartments: Function,
    checkUpdated: Function,
    items: Array,
  },
  computed: {
    department: {
      // eslint-disable-next-line
      get: function () {
        return this.value;
      },
      // eslint-disable-next-line
      set: function (newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  data() {
    return {
      disableAutoComplete: false,
      departmentName: null,
      departmentTempName: null,
      removeDepartmentId: null,
      changeButtonColor: '',
      removeDepartmentName: '',
    };
  },
  mounted() {
    const autocompleteInput = this.$refs.autocomplete.$refs.input;
    autocompleteInput.addEventListener('focus', this.focusAutoSelect, true);
    autocompleteInput.addEventListener('blur', this.blurAutoSelect, true);
  },
  methods: {
    async newDepartmentEv() {
      await this.departmentSave();
    },
    async departmentSave(id) {
      if ((this.departmentName != null && this.departmentName !== '')
        || (this.departmentTempName != null && this.departmentTempName !== '')) {
        const departmentName = this.departmentTempName ? this.departmentTempName
          : this.departmentName;
        this.departmentName = '';
        this.disableAutoComplete = true;
        try {
          await this.$api.manage.saveDepartment(departmentName, id);
          this.$ref.modalUpdateDepartment.close();
        } catch (error) {
          console.log(error);
        }
        this.disableAutoComplete = false;
        this.departmentTempName = null;
        await this.getDepartments();
        this.checkUpdated();
      }
    },
    focusAutoSelect() {
      this.changeButtonColor = '#ff0d00';
    },
    blurAutoSelect() {
      this.departmentTempName = this.departmentName;
      this.changeButtonColor = '';
      this.$refs.autocomplete.blur();
    },
    openModalUpdateDepartment() {
      this.$refs.modalUpdateDepartment.open();
    },
    openModalDeleteDepartment(id, name) {
      this.removeDepartmentId = id;
      this.removeDepartmentName = name;
      this.$ref.modalDeleteDepartment.open();
    },
    async confirmDeleteDepartment() {
      if (!this.removeDepartmentId) return;
      await this.$api.manage.deleteDepartment(this.removeDepartmentId);
      this.$emit('ressetTable');
      await this.getDepartments();
      this.$ref.modalDeleteDepartment.close();
      this.removeDepartmentName = '';
    },
  },
  watch: {
    departmentName() {
      if (this.departmentName !== '') return;
      this.$emit('ressetTable');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-department {
  min-width: 250px;
  max-width: 360px;
  display: inline-block;
  top: 0;
}
.department {
  display: inline-block;
  .v-autocomplete__content {
    max-width: 233px;
    min-width: 223px !important;
  }
}
.updated-department {
  margin-left: 10px;
  margin-bottom: 14px;
}
.margin-group {
  margin-top: 70px;
  @media (max-width: 1170px) {
    margin-top: 140px;
  }
}
</style>
